
import { mapState, mapGetters } from 'vuex'
import Datatable from '@/mixins/dataTable'
export default {
    mixins: [Datatable],
    data() {
        return {
            api: 'themes/transaction/getTransactions',
            historyType: 'financial',
            filter: null,
            page: 1,
        }
    },
    computed: {
        ...mapState({
            player: (state) => state.player.player,
        }),
        ...mapGetters({
            paymentTypes: 'themes/payment/paymentTypes',
            transaction: 'themes/transaction/transaction',
        }),
    },
    mounted() {
        this.$root.isTransactionHistoryPage = true
        this.customFilters = {
            language: this.handleCookie('wl_locale'),
            start_date: '2022-06-01 00:00:00',
            end_date: this.$dayjs().format('YYYY-MM-DD') + ' 23:59:59',
        }
        if (this.transaction?.data?.length) return
        this.API()
    },
    beforeDestroy() {
        this.doCancelAxiosRequest()
        this.$store.commit('themes/transaction/setTransaction', {
            total: 0,
            total_amount: 0,
            totalPages: 0,
            data: [],
        })
    },
    destroyed() {
        this.$root.isTransactionHistoryPage = false
    },
    methods: {
        doSubmitSearch() {
            this.API()
        },
        async cancelPending(item) {
            if (item.status === 1) {
                const confirm = window.confirm(this.tl('uSureToCancel?'))
                if (!confirm) return
                const result = await this.$store.dispatch(
                    'themes/withdraw/cancelWithdraw',
                    item.id
                )
                if (result) {
                    this.API()
                }
            }
        },
        changePage() {
            if (this.customFilters.page !== this.page) {
                this.customFilters.page = this.page
                this.API()
            }
        },
        updateStartDate(date) {
            this.customFilters.start_date = date
        },
        updateEndDate(date) {
            this.customFilters.end_date = date
        },
    },
}
