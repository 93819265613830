
import { mapGetters, mapState } from 'vuex'
import bankAccount from '~/mixins/common/bank-account'
import finance from '~/mixins/common/pages/finance'
export default {
    mixins: [finance, bankAccount],
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            steppers: 1,
            selectedBank: 1,
            amount: '',
            formData: {
                bankingType: 'credit',
            },
            form: {
                amount: '',
            },
            selectedBankId: null,
            pleaseSelectedBankId: false,
            receiverBankInfo: {},
            valid: true,
            nextStepBtnLoading: false,
            cancelBtnLoading: false,
            countingDownInterval: null,
            remainingTime: '-:-',
        }
    },
    computed: {
        ...mapState({
            tempData: (state) => state.themes.deposit.tempData,
            player: (state) => state.player.player,
            checkExist: (state) => state.themes.deposit.checkExist,
        }),
        ...mapGetters({
            bankAccount: 'themes/player-bank/bankAccount',
        }),
        months() {
            return Array.from({ length: 12 }, (_, i) => this.timeFormat(i + 1))
        },
        years() {
            return Array.from(
                { length: 10 },
                (_, i) => new Date().getFullYear() + i
            )
        },
        bankData() {
            if (this.receiverBankInfo.created_at) {
                const info = this.receiverBankInfo
                return {
                    reference_id: info?.bank_transaction_id,
                    deposit_id: info?.deposit_id,
                    bank_name: info?.bank_info?.bank_name,
                    bank_branch: info?.bank_info?.bank_branch,
                    bank_account_number: info?.bank_info?.bank_account_number,
                    bank_account_name: info?.bank_info?.bank_account_name,
                    created_at: info?.created_at,
                    deposit_amount: info?.deposit_amount,
                }
            }
            if (this.tempData.bank_info) {
                return {
                    reference_id: this.tempData?.reference_id,
                    deposit_id: this.tempData?.deposit_id,
                    bank_name: this.tempData?.bank_info?.bank_name,
                    bank_branch: this.tempData?.bank_info?.bank_branch,
                    bank_account_number:
                        this.tempData?.bank_info?.bank_account_number,
                    bank_account_name:
                        this.tempData?.bank_info?.bank_account_name,
                    created_at: this.tempData?.created_at,
                    deposit_amount: this.tempData?.deposit_amount,
                }
            }
            return {}
        },
    },
    watch: {
        checkExist(value) {
            this.receiverBankInfo = {}
            if (value && value.is_exist !== false) {
                this.receiverBankInfo = value

                if (value?.created_at && value.deposit_status === 1) {
                    this.steppers = 2
                    this.countingDown(value?.created_at)
                } else if ([2, 3, 4].includes(value.deposit_status)) {
                    this.steppers = 3
                }
            } else {
                this.steppers = 1
                if (this.countingDownInterval) {
                    this.clearTimeout()
                }
            }
        },
    },
    beforeDestroy() {
        this.clearTimeout()
    },

    methods: {
        timeFormat(value) {
            return ('0' + parseInt(value || '00')).slice(-2)
        },
        async onNextDeposit() {
            if (this.$refs.form.validate()) {
                this.nextStepBtnLoading = true
                if (this.selectedBankId) {
                    this.form.player_bank_account_id = this.selectedBankId
                } else {
                    delete this.form.player_bank_account_id
                }
                const response = await this.$store.dispatch(
                    'themes/deposit/create',
                    this.form
                )
                if (response) {
                    this.steppers++
                    this.countingDown(response.created_at)
                    this.$emit('enableDisabledPaymentMethod', {
                        is_exist: true,
                        payment_provider_code: 'manual_transfer',
                    })
                }
                this.nextStepBtnLoading = false
            }
        },
        onInputAmount(amount) {
            if (isNaN(amount?.replace(/,/g, ''))) {
                this.form.amount = '0'
                return
            }
            this.amount = amount
                ? Number.parseFloat(amount?.replace(/,/g, ''))?.toLocaleString()
                : ''
            this.form.amount = this.amount?.toString()?.replace(/,/g, '')
        },
        countingDown(createdAt) {
            const start = this.$dayjs(createdAt)
            this.countingDownInterval = setInterval(() => {
                const end = this.$dayjs()

                const duration = this.$dayjs.duration(end.diff(start))
                const minutes = duration.minutes().toString().padStart(2, '0')
                const seconds = duration.seconds().toString().padStart(2, '0')

                const currentDuration = this.$dayjs.duration({
                    minutes,
                    seconds,
                })
                const totalDuration = this.$dayjs.duration(10, 'minute')
                totalDuration.subtract(2, 'second')

                const timeLeft = this.$dayjs.duration(
                    totalDuration - currentDuration
                )

                const minutesLeft = timeLeft.minutes()
                const secondsLeft = timeLeft.seconds()

                if (
                    (minutesLeft === 0 && secondsLeft <= 0) ||
                    minutesLeft <= 0
                ) {
                    this.steppers = 3
                    this.clearTimeout()
                }
                if (minutesLeft >= 0 && secondsLeft >= 0) {
                    const minutesFormatted = minutesLeft
                        .toString()
                        .padStart(2, '0')
                    const secondsFormatted = secondsLeft
                        .toString()
                        .padStart(2, '0')

                    this.remainingTime = `${minutesFormatted}:${secondsFormatted}`
                }
            }, 1000)
        },
        async onConfirmDeposit() {
            this.nextStepBtnLoading = true
            const response = await this.$store.dispatch(
                'themes/deposit/confirm',
                this.bankData.deposit_id
            )
            if (response) {
                this.steppers++
            }
            this.nextStepBtnLoading = false
        },
        async onRevokeDeposit() {
            this.cancelBtnLoading = true
            const response = await this.$store.dispatch(
                'themes/deposit/revoke',
                this.bankData.deposit_id
            )
            if (response) {
                this.steppers--
                this.clearTimeout()
                this.receiverBankInfo = {}
                this.$emit('enableDisabledPaymentMethod', {
                    is_exist: false,
                })
            }
            this.cancelBtnLoading = false
        },
        clearTimeout() {
            if (this.countingDownInterval) {
                clearInterval(this.countingDownInterval)
                this.countingDownInterval = null
            }
        },
    },
}
