
import Login from '~/mixins/pages/login/login'

export default {
    name: 'Theme3MobileLeLoginModal',
    mixins: [Login],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isForgotPassword: false,
        }
    },
    computed: {
        modal: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            },
        },
    },
}
