
import { mapState, mapGetters } from 'vuex'
import payment from '~/mixins/common/payment'
import finance from '~/mixins/common/pages/finance'
import bankAccount from '~/mixins/common/bank-account'
import staticAmount from '~/static/json/amount.json'
export default {
    mixins: [payment, finance, bankAccount],
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            steppers: 1,
            valid: true,
            depositOption: '',
            depositChannelList: [],
            channelList: {},
            formData: {
                third_party_account_uuid: null,
            },
            depositAmount: '',
            createDepositLoading: false,
            selectedBankId: null,
            loadingProvider: false,
        }
    },
    computed: {
        ...mapState({
            thirdPartyPayments: (state) =>
                state.themes.deposit.thirdPartyPayments,
            player: (state) => state.player.player,
            checkExist: (state) => state.themes.deposit.checkExist,
        }),
        ...mapGetters({
            promotionGetter: 'cms/promotion',
            bankAccount: 'themes/player-bank/bankAccount',
        }),
        promotion() {
            return this.promotionGetter(this.$config.THEME)
        },
        selectAmount() {
            const res = staticAmount.find(
                (value) => value.currentcy === this.$cookie.get('currency')
            )
            return res.amount
        },
    },
    watch: {
        thirdPartyPayments(value) {
            if (value?.length > 0) {
                this.depositOption = value[0].id
                this.depositChannelList = value[0]?.channels
            }
        },
        depositOption(value) {
            if (value) {
                const channelList = this.thirdPartyPayments.find(
                    (el) => el.id === value
                )
                this.depositChannelList = channelList?.channels
            }
        },
        depositChannelList(value) {
            if (value?.length > 0) {
                this.formData.third_party_account_uuid = value[0].id
            }
        },
        'formData.third_party_account_uuid'(value) {
            if (value) {
                const chList = this.depositChannelList.find(
                    (el) => el.id === value
                )
                this.channelList = chList ?? {}
            }
        },
        checkExist(value) {
            // this.$emit('onlinePaymentConfirm', value?.is_exist)
            if (value.is_exist) {
                this.onlineBankCheckIsExist(value)
            } else {
                this.steppers = 1
            }
        },
    },
    created() {
        this.showDisableAccount = true
    },
    mounted() {
        this.fetchingProviders()
        if (this.checkExist?.is_exist) {
            this.onlineBankCheckIsExist(this.checkExist)
        }
    },
    methods: {
        async fetchingProviders() {
            if (this.thirdPartyPayments.length !== 0) {
                if (this.thirdPartyPayments?.length > 0) {
                    this.depositOption = this.thirdPartyPayments[0].id
                }
                return
            }
            this.loadingProvider = true
            await this.$store.dispatch('themes/deposit/getThirdPartyPayments')
            this.loadingProvider = false
        },
        timeFormat(value) {
            return ('0' + parseInt(value || '00')).slice(-2)
        },
        formStep() {
            if (this.$refs.form.validate()) {
                this.steppers = this.steppers + 1
            }
        },
        onlineBankCheckIsExist(data) {
            if (data.is_exist) {
                this.steppers = 2
            }
        },
        clearForm() {
            this.depositAmount = ''
            this.selectedBankId = null
        },
        async onConfirmAndPay() {
            if (this.$refs.form.validate()) {
                this.createDepositLoading = true
                const form = {
                    amount: this.formData.amount,
                    third_party_account_uuid:
                        this.formData.third_party_account_uuid,
                    player_bank_account_id: this.selectedBankId,
                }
                if (this.selectedBankId) {
                    delete form.player
                }
                const response = await this.$store.dispatch(
                    'themes/deposit/createDepositThirdParty',
                    form
                )

                this.createDepositLoading = false
                if (response) {
                    this.$emit('enableDisabledPaymentMethod', {
                        is_exist: true,
                        payment_provider_code: 'third_party',
                    })
                    this.steppers = this.steppers + 1
                }
            }
        },
        onInputAmount(amount, key) {
            if (isNaN(amount?.toString()?.replace(/,/g, ''))) {
                this.formData[key] = '0'
                return
            }
            if (key === 'amount') {
                this.depositAmount = this.customFormatNumber(
                    amount?.toString()?.replace(/,/g, '')
                )
                this.formData[key] = this.depositAmount
                    ?.toString()
                    ?.replace(/,/g, '')
            }
        },
        onSelectAmount(amount) {
            this.amount = amount?.toString()
            this.depositAmount = this.customFormatNumber(
                amount?.toString()?.replace(/,/g, '')
            )
            this.formData.amount = amount?.toString()
        },
        formatNumber(number) {
            return this.customFormatNumber(
                number?.toString()?.replace(/,/g, '')
            )
        },
    },
}
