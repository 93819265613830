
import { mapState } from 'vuex'
import finance from '~/mixins/common/pages/finance'
export default {
    mixins: [finance],
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            steppers: 1,
            form: {
                amount: '',
                network: 'TRC20',
                currency: null,
            },
            input_value: null,
            exchange_amount: null,
            receiverBankInfo: {},
            depositLoading: false,
            cryptoValid: false,
            bank: {
                min_deposit: 0,
                max_deposit: 0,
            },
            loadingCryptoCurrencyAddress: false,
        }
    },
    computed: {
        ...mapState({
            player: (state) => state.player.player,
            checkExist: (state) => state.themes.deposit.checkExist,
            companyCryptoWallet: (state) =>
                state.themes.deposit.companyCryptoWallet,
            exchangeRateByPlayerCurrency: (state) =>
                state.themes.settings.exchangeRateByPlayerCurrency,
        }),
        cryptoWalletSetting() {
            return (
                this.companyCryptoWallet.find(
                    (el) => el.currency === this.form.currency
                ) ?? {}
            )
        },
        cryptoExchangeRate() {
            return this.exchangeRateByPlayerCurrency?.reversed_rate
        },
        networkList() {
            if (!this.form.currency) return []
            const findCryptoWallet = this.companyCryptoWallet.filter(
                (el) => el.currency === this.form.currency
            )
            const getUniqueNetworkList = findCryptoWallet.map((el) => {
                return el.network
            })
            const uniqueNetworkList = [...new Set(getUniqueNetworkList)]
            return uniqueNetworkList
        },
        actualDeposit() {
            const value =
                parseFloat(this.cryptoExchangeRate) *
                parseFloat(this.form.amount)
            return value || '0'
        },
    },
    watch: {
        checkExist(value) {
            if (value) {
                this.isExist()
            }
        },
        'form.currency'(value) {
            this.bank.deposit_max = parseFloat(
                this.cryptoWalletSetting?.max_deposit
            )
            this.bank.deposit_min = parseFloat(
                this.cryptoWalletSetting?.min_deposit
            )
        },
        companyCryptoWallet(value) {
            if (!this.form.currency && value && value.length) {
                this.checkWalletAndSetDefault(value)
            }
        },
    },
    async mounted() {
        // To get the crypto exchange
        if (this.$cookie.get('player')?.currency !== 'USDT')
            this.$store.dispatch(
                'themes/settings/getExchangeRateByPlayerCurrency',
                {
                    currency: this.$cookie.get('player')?.currency,
                }
            )
        this.loadingCryptoCurrencyAddress = true
        this.isExist()
        await this.$store.dispatch('themes/deposit/getCompanyCryptoWallet', {})

        // Check if the company crypto wallet exist, and there is only 1 array element, then it default currency and network
        this.checkWalletAndSetDefault()
        this.loadingCryptoCurrencyAddress = false
    },
    methods: {
        isExist() {
            if (
                this.checkExist &&
                this.checkExist.is_exist === true &&
                this.checkExist.payment_provider_code === 'crypto'
            ) {
                this.receiverBankInfo = this.checkExist
                if (
                    this.checkExist?.created_at &&
                    this.checkExist.deposit_status === 1
                ) {
                    this.steppers = 2
                } else if ([2, 3, 4].includes(this.checkExist.deposit_status)) {
                    this.steppers = 3
                }
            } else {
                this.steppers = 1
            }
        },
        async firstForm() {
            if (this.$refs.firstForm.validate()) {
                this.depositLoading = true
                const response = await this.$store.dispatch(
                    'themes/deposit/depositCrypto',
                    this.form
                )
                if (response) {
                    this.receiverBankInfo = response
                    this.steppers = 2
                    this.depositLoading = false
                }
                this.depositLoading = false
            }
        },
        async onConfirmDeposit() {
            this.depositLoading = true
            const response = await this.$store.dispatch(
                'themes/deposit/confirm',
                this.receiverBankInfo.id
            )
            if (response) {
                this.steppers++
                this.$emit('enableDisabledPaymentMethod', {
                    is_exist: true,
                    payment_provider_code: 'crypto',
                })
            }
            this.depositLoading = false
        },
        async onRevokeDeposit() {
            this.depositLoading = true
            const response = await this.$store.dispatch(
                'themes/deposit/revoke',
                this.receiverBankInfo.id
            )
            if (response) {
                this.steppers--
                this.receiverBankInfo = {}
                this.clearForm()
            }
            this.depositLoading = false
        },
        clearForm() {
            this.form = {}
            this.checkWalletAndSetDefault()
            this.$refs.firstForm.resetValidation()
        },
        findSmallestNumber() {
            return this.bank.deposit_min / this.cryptoExchangeRate
        },
        findLargestNumber() {
            return Math.floor(this.bank.deposit_max / this.cryptoExchangeRate)
        },
        checkWalletAndSetDefault(
            companyCryptoWalletList = this.companyCryptoWallet
        ) {
            if (companyCryptoWalletList.length) {
                const currencyData = companyCryptoWalletList[0]
                this.form.currency = currencyData.currency
                this.form.network = currencyData.network
            }
        },
    },
}
